import React, { useState } from 'react';
import { CImage } from "@coreui/react";

const DoctorItem = ({ item }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className={`flex flex-col transition ease-in-out duration-300 p-2 rounded-[5px] ${isHovered ? 'shadow-lg' : ''} justify-between w-[16rem] max-lg:w-[13rem]`}
        >
            <div className='flex flex-col justify-center items-center'>
                <CImage className="w-[300px] rounded-[4px] bg-white" src={item.photo} alt={item.name} />
                <h3 className='max-w-[420px] pt-[10px] text-center'>{item.name}</h3>
                <div className='text-center'>{item.doctor}</div>
            </div>
            <a
                id="hover-button"
                href={`/doctors/${item.id}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={`bg-tailprimary hover:bg-black rounded-[3px] transition ease-in-out duration-300 text-white mt-[20px] p-2 w-fit self-center ${isHovered ? 'text-[#F2AE30]' : ''}`}
            >
                Детальніше
            </a>
        </div>
    );
}

export default DoctorItem;
