import React from 'react';
import "../styles/DoctorCard.css";

const DoctorCard = ({ el }) => {
    return (
        <div className='flex w-full '>
            <div className='flex max-[1425px]:flex-col max-[1425px]:items-center  w-full gap-[40px]  border-2 border-tailgray rounded-[3px] shadow-md p-[20px]'>
                <div className='flex !bg-white min-w-[560px] max-[800px]:min-w-[220px] h-full'>
                    <img src={el.photo} alt={`${el.name}`} className="object-cover h-[560px]" />
                </div>
                <div className='flex flex-col w-full p-[20px] justify-between max-[1425px]:px-[20px] max-[800px]:w-fit  max-[1425px]:items-start max-[1425px]:w-[560px]'>
                    <h2 className='text-left '>{el.name}</h2>
                    <div className='font-semibold '>{el.specialization}. Стаж {el.experience}.</div>
                    <div className='flex flex-col items-start justify-start font-semibold   text-left'>
                        <div>
                            <h3>Що лікує:</h3>
                            {el.treatment.name}
                            {el.treatment.text.length > 0 && ':'}
                        </div>
                        <ul>
                            {el.treatment.text.map((text, index) => (
                                <li key={index}>{text}</li>
                            ))}
                        </ul>
                    </div>
                    <div className='flex flex-col items-start font-semibold justify-start text-left'>
                        <h3>Які процедури виконує:</h3>
                        <ul>
                            {el.procedures.map((procedure, index) => (
                                <li key={index}>{procedure}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DoctorCard;
