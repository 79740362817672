import React from 'react';
import { useParams } from "react-router-dom";
import DoctorCard from "../components/DoctorCard";
import ServiceItem from "../components/ServiceItem";
import PreFooter from "../components/Pre-footer";

const Doctor = ({ doctorsData, customerServices }) => {
    const params = useParams();
    const doctorId = Number(params.id);

    const filteredDoctor = doctorsData.find(el => el.id === doctorId);
    const filteredServices = customerServices.filter(el => filteredDoctor.treatments.includes(el.treatments));

    return (
        <div className='flex flex-col w-full items-center justify-center'>
            <div className='flex flex-col w-[70%] py-[20px] max-md:w-full gap-2'>
                <div className='flex flex-col w-full text-left items-start justify-start gap-3 p-[10px] '>
                    {filteredDoctor && <DoctorCard el={filteredDoctor}/>}
                </div>
            </div>
            <div className='flex flex-col w-[70%] max-md:w-full gap-2'>
                <h2>ПЕРЕЛІК ПОСЛУГ ТА ЇХ ВАРТІСТЬ</h2>
                <div className='flex flex-col w-full text-left gap-2 my-[20px] items-start justify-start'>
                    {filteredServices.map((service, index) => (
                        <ServiceItem key={index} el={service}/>
                    ))}
                </div>
            </div>
            <PreFooter/>
        </div>
    );
}

export default Doctor;
