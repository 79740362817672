import React, { useEffect } from 'react';

const Reviews = () => {
    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;

            // Условие для загрузки скрипта в зависимости от ширины экрана
            if (screenWidth > 370) {
                // Загружаем скрипт для ширины экрана >= 768px
                if (!document.querySelector('script[src="https://static.elfsight.com/platform/platform.js"]')) {
                    const script = document.createElement('script');
                    script.src = "https://static.elfsight.com/platform/platform.js";
                    script.setAttribute('data-use-service-core', '');
                    script.defer = true;

                    script.onload = () => {
                    };

                    document.body.appendChild(script);
                }
            } else {
                // Удаляем скрипт для ширины экрана < 768px
            }
        };

        // Вызовем функцию один раз при монтировании компонента
        handleResize();

        // Добавляем обработчик события изменения размера окна
        window.addEventListener('resize', handleResize);

        return () => {
            // Удаляем обработчик события изменения размера окна при размонтировании компонента
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div className='reviews-style' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', paddingTop: '20px' }}>
            <div className="elfsight-app-1be010c6-15f4-43cf-a242-5e03b1f4fb0b" data-elfsight-app-lazy></div>
        </div>
    );
};

export default Reviews;
