import React, { useEffect, useState } from 'react';
import '@/styles/Header.css';
import logo from '@/assets/logo.png';
import logoRed from '@/assets/logo-red.png';
import mail from '@/assets/icons/mail.svg';
import phone from '@/assets/icons/phone.svg';
import clock from '@/assets/icons/clock.svg';
import menu from '@/assets/icons/menu.svg';
import { useLocation } from "react-router-dom";

const Header = () => {
    const location = useLocation();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        document.body.style.overflow = visible ? 'hidden' : 'auto';
    }, [visible]);

    const menuItems = [
        { href: "/", label: "Головна" },
        { href: "/doctors", label: "Лікарі" },
        { href: "/treatment", label: "Лікування" },
        { href: "/contact", label: "Контакт" },
        { href: "/price", label: "Послуги та ціни" },
    ];

    const contactDetails = [
        { icon: clock, title: "РОБОЧІ ГОДИНИ", details: "Пн - Пт: 09:00–15:30" },
        { icon: phone, title: "ПОДЗВОНІТЬ НАМ", details: "(044) 528 64 82" },
        { icon: mail, title: "НАПИШІТЬ НАМ", details: "info@ganimed.ua" },
    ];

    return (
        <div className='flex justify-center h-[160px] bg-tailprimary text-white border-b-[1px] border-white'>
            <div className='flex items-center gap-5 max-lg:w-[70%] max-lg:justify-between'>
                <img src={logo} alt='logo' className='h-[125px] max-md:hidden'/>
                <div className={`mt-[15px]`}>
                    <h1>ГАНІМЕД</h1>
                    <p>Медичний центр</p>
                </div>
                <div
                    className={`flex cursor-pointer lg:hidden transition duration-300 ${visible ? 'rotate-[270deg]' : ''}`}
                    onClick={() => setVisible(!visible)}
                >
                    <div>
                        <img src={menu} className='w-[48px]' alt='menu'/>
                    </div>
                </div>
                <div className='flex items-center gap-5 max-lg:hidden'>
                    {contactDetails.map((detail, index) => (
                        <div key={index} className='flex text-left justify-center gap-2'>
                            <img className='w-[35px]  logo-white' src={detail.icon} alt={detail.title} />
                            <div className='flex-col content-center'>
                                <div>{detail.title}</div>
                                <div>{detail.details}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <div className={`transition duration-700 ${!visible ? 'hidden translate-x-[-1500px]' : ''} fixed top-0 left-0 z-[999999] h-full w-[60%] bg-gray-100`}>
                    <div className="flex flex-col text-tailprimary justify-center items-center pt-[10px]">
                        <img src={logoRed} alt='logo' className='w-[160px]'/>
                        <h1>ГАНІМЕД</h1>
                        <p>Медичний центр</p>
                    </div>
                    <div className="flex">
                        <div className='flex flex-col w-full'>
                            {menuItems.map((item, index) => (
                                <a
                                    key={index}
                                    href={item.href}
                                    className='transition !text-tailprimary hover:bg-tailprimary hover:!text-white p-2'
                                >
                                    {item.label}
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className='flex flex-col max-sm:pl-[20px] sm:pl-[28%] pt-[20px] gap-[20px] text-tailprimary'>
                        {contactDetails.map((detail, index) => (
                            <div key={index} className='flex text-left gap-2'>
                                <img className='w-[35px] logo' src={detail.icon} alt={detail.title} />
                                <div className='flex-col content-center'>
                                    <div>{detail.title}</div>
                                    <div>{detail.details}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className={`transition duration-200 ${!visible ? 'hidden !h-0 !w-0 bg-transparent' : ''} fixed top-0 left-0 z-[99999] h-full w-full bg-black opacity-75`}
                    onClick={() => setVisible(false)}
                />
            </div>
        </div>
    );
}

export default Header;
