import React, { useEffect, useState } from 'react';
import arrowTop from "@/assets/icons/arrow-top.svg";

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 250) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div
            className={`fixed transition duration-[400ms] ${!isVisible && 'translate-y-[500px]'} hover:scale-[1.2] cursor-pointer right-20 bottom-[4rem] bg-white rounded-full z-[9999999]`}
            onClick={scrollToTop}
        >
            <img src={arrowTop} alt="Scroll to top" className="m-[5px] h-[40px] w-[40px]" />
        </div>
    );
}

export default ScrollToTopButton;
