import React from 'react';
import '@/styles/Treatment.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import TreatmentsItem from "../components/TreatmentsItem";
import andrology from '@/assets/icons/sperm.png';
import dermatology from '@/assets/icons/skin.png';
import gynecology from '@/assets/icons/vagina.png';
import mamology from '@/assets/icons/breast.png';
import otolaryngology from '@/assets/icons/throat.png';
import proctology from '@/assets/icons/intestine.png';
import ultrasound from '@/assets/icons/ultrasound.png';
import venerology from '@/assets/icons/sex.png';
import PreFooter from "../components/Pre-footer";
import photo from '@/assets/slide3.jpeg';

const Treatments = () => {
    const treatmentItems = [
        { href: 'andrology', img: andrology, name: 'АНДРОЛОГІЯ' },
        { href: 'dermatology', img: dermatology, name: 'ДЕРМАТОЛОГІЯ' },
        { href: 'gynecology', img: gynecology, name: 'ГІНЕКОЛОГІЯ' },
        { href: 'mamology', img: mamology, name: 'МАМОЛОГІЯ' },
        { href: 'otolaryngology', img: otolaryngology, name: 'ОТОЛАРИНГОЛОГІЯ' },
        { href: 'venerology', img: venerology, name: 'ВЕНЕРОЛОГІЯ' },
        { href: 'ultrasound', img: ultrasound, name: 'УЗД' }
    ];

    return (
        <>
            <div className='flex flex-col w-full h-[21rem] justify-center items-center content-center shadow-xl'>
                <div className="image-container">
                    <img src={photo} className='brightness-[0.3]' alt="Image" />
                    <div className='overlay text-white justify-center items-center content-center'>
                        <h1 className='uppercase'>напрямки лікування</h1>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-2 justify-center items-center'>
                <div className='flex flex-wrap justify-center items-start min-h-[70vh] py-[25px] w-[70%] gap-[25px]'>
                    {treatmentItems.map((item, index) => (
                        <TreatmentsItem key={index} href={item.href} img={item.img} name={item.name} />
                    ))}
                </div>
            </div>
            <div className='flex flex-col items-center'>
                {/*<div className='flex text-left font-semibold justify-center border-b-2 border-gray-200 m-[40px] w-[70%]'>
                    Звернувшись до нашого центру, Ви можете бути певні щодо високої якості послуг, адже досвідчені лікарі нададуть Вам кваліфіковану допомогу за доступними цінами. Фахівці центру допоможуть не тільки діагностувати захворювання на ранній стадії, а й на підставі проведених обстежень, причому в найкоротші терміни, складуть точний висновок та оптимальний план лікування.
                </div>*/}
                <PreFooter />
            </div>
        </>
    );
}

export default Treatments;
