import React, {useEffect, useState} from 'react';
import pillor from '@/assets/pillor.png';

const PreFooter = () => {
    const [isVisible, setIsVisible] = useState(false);


    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 250) {
                setIsVisible(true);
            } else {
            }
        };

        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };



    return (
        <div className='flex  justify-center items-center w-full bg-tailprimary'>
            <div className='flex relative flex-col text-white justify-center items-center w-[75%] my-[20px]'>
                <div className={`flex absolute top-0 justify-center items-center pb-[30px] transition duration-[4400ms] ${!isVisible && 'w-0 translate-x-[3200px]'} translate-x-[0px] `}>
                    <img src={pillor} className='transform -scale-y-100'/>
                    <img src={pillor} className='transform -scale-y-100'/>
                    <img src={pillor} className='transform -scale-y-100'/>
                </div>
                <div className={`flex flex-col py-[70px] justify-center items-center`}>
                    <h2 className='p-2'>Запишіться на прийом</h2>
                    <p>
                        Подайте заявку онлайн і наш консультант обов'язково Вам перетелефонує для підтвердження
                        інформації
                    </p>
                    <a
                        href="/contact"
                        className='cursor-pointer bg-tailprimary lg:transition outline outline-[2px] rounded-[3px] hover:bg-black hover:outline-black p-2 m-4 border-white w-fit'
                    >
                        Записатись онлайн
                    </a>
                </div>
                <div
                    className={`flex absolute bottom-0 justify-center items-center pt-[30px] transition duration-[4400ms] ${!isVisible && 'w-0 translate-x-[-3200px]'} translate-x-[0px] `}>
                    <img src={pillor} className='transform scale-y-100'/>
                    <img src={pillor} className='transform scale-y-100'/>
                    <img src={pillor} className='transform scale-y-100'/>
                </div>
            </div>
        </div>
    );
}

export default PreFooter;
