import React, {useEffect, useState} from 'react';
import '@/styles/Treatment.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import { useParams } from "react-router-dom";
import ServiceItem from "../components/ServiceItem";
import DoctorCard from "../components/DoctorCard";
import PreFooter from "../components/Pre-footer";
import pillor from '@/assets/pillor.png';

const Treatment = ({ doctorsData, customerServices }) => {

    const [isVisible, setIsVisible] = useState(false);


    const params = useParams();
    const treatmentId = params.id;

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 0) {
                setIsVisible(true);
            } else {
            }
        };

        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const treatmentNameMap = {
        gynecology: 'ГІНЕКОЛОГІЯ',
        andrology: 'АНДРОЛОГІЯ',
        otolaryngology: 'ОТОЛАРИНГОЛОГІЯ',
        venerology: 'ВЕНЕРОЛОГІЯ',
        dermatology: 'ДЕРМАТОЛОГІЯ',
        mamology: 'МАМОЛОГІЯ',
        ultrasound: 'УЗД-ДІАГНОСТИКА'
    };

    const treatmentName = treatmentNameMap[treatmentId];

    const filteredDoctors = doctorsData.filter(el => el.treatments.includes(treatmentId));
    const filteredServices = customerServices.find(el => el.treatments.includes(treatmentId));

    return (
        <div className='flex flex-col w-full justify-items-center items-center'>
            <div className='flex flex-col w-full h-[21rem] justify-center items-center content-center shadow-xl'>
                <div className="image-container">
                    <img src={filteredServices.photo} className='brightness-50' alt={treatmentName}/>
                    <div className='overlay text-white justify-center items-center content-center'>
                        <h1>{treatmentName}</h1>
                    </div>
                </div>
            </div>
            <div className='flex relative  justify-center py-[20px] bg-tailprimary text-white'>
                <div className='flex flex-col w-[70%] max-[1060px]:w-[80%] gap-[40px] py-[100px] '>
                    <div
                        className={`flex absolute top-0 justify-center items-center py-[20px] transition duration-[4400ms] ${!isVisible && 'w-0 translate-x-[3200px]'} translate-x-[0px] `}>
                        <img src={pillor} className='transform -scale-y-100'/>
                        <img src={pillor} className='transform -scale-y-100'/>
                        <img src={pillor} className='transform -scale-y-100'/>
                    </div>
                    <div>
                        <h1>Як відбувается консультація</h1>
                    </div>
                    <div className='flex max-xl:flex-col gap-[10px]'>
                        <div className='text-left'>
                            <h2>ЗБІР ІНФОРМАЦІЇ</h2>
                            <p>
                                Збір інформації про перебіг та тривалість захворювання (історія хвороби), особливості
                                розвитку, перенесені захворювання (анамнез життя).
                                Лікар запитає Вас про наявність алергічних реакцій, використання лікарських засобів.
                            </p>
                        </div>
                        <div className='text-left'>
                            <h2>ОГЛЯД</h2>
                            <p>
                                {treatmentId !== 'otolaryngology'
                                    ? 'Лікар проведе спеціальне обстеження на виявлення патологічних змін. Може виникнути необхідність для проведення додаткових методів обстеження.'
                                    : 'Лікар проведе спеціальне обстеження на виявлення патологічних змін з боку ЛОР-органів (вухо, горло, ніс). Може виникнути необхідність для проведення додаткових методів обстеження (рентгенографія, КТ, ін.).'}
                            </p>
                        </div>
                        <div className='text-left'>
                            <h2>ДІАГНОЗ ТА ЛІКУВАННЯ</h2>
                            <p>
                                Лікар встановлює попередній діагноз, видає консультативний висновок, та, за потреби,
                                скеровує на додаткові методи обстеження, для встановлення остаточного діагнозу та
                                визначення
                                подальшої тактики лікування.
                            </p>
                        </div>
                        <div
                            className={`flex absolute bottom-0 justify-center items-center py-[20px] transition duration-[4400ms] ${!isVisible && 'w-0 -translate-x-[3200px]'} translate-x-[0px] `}>
                            <img src={pillor} className='transform scale-y-100'/>
                            <img src={pillor} className='transform scale-y-100'/>
                            <img src={pillor} className='transform scale-y-100'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col w-[70%] max-md:w-full py-[20px] gap-2'>
                <div className='flex gap-2 justify-center'>
                    <h2 className='uppercase'>Наші лікарі</h2>
                </div>
                <div className='flex flex-col w-full text-left items-start justify-start gap-3 p-[10px] '>
                    {filteredDoctors.map((doctor, index) => (
                        <DoctorCard key={index} el={doctor}/>
                    ))}
                </div>
            </div>
            <div className='flex flex-col w-[70%] max-md:w-full gap-2'>
                <h2>ПЕРЕЛІК ПОСЛУГ ТА ЇХ ВАРТІСТЬ</h2>
                <div className='flex flex-col w-full text-left gap-2 my-[20px] items-start justify-start'>
                    {filteredServices && <ServiceItem el={filteredServices}/>}
                </div>
            </div>
            <PreFooter/>
        </div>
    );
}

export default Treatment;
