import photoOlaryngology from '@/assets/otolaryngology-photo.png';
import photoDermatology from '@/assets/dermatology-photo.png';
import photoGynecology from '@/assets/gynecology-photo.png';
import photoAndrology from '@/assets/andrology-photo.png';
import photoVenerology from '@/assets/venerology-photo.png';
import photoMamology from '@/assets/mamology-photo.png';
import photoUltrasound from '@/assets/ultrasound-photo.png';
import photoProctology from '@/assets/proctology-photo.png';

export const customerServices = [
    {
        "name": "ОТОЛАРИНГОЛОГІЯ",
        "treatments": "otolaryngology",
        "photo": photoOlaryngology,
        "consultations": [
            {
                text: 'Консультація отоларинголога (встановлення діагнозу, спеціальний огляд, план обстеження, попередній план лікування)',
                price: 700
            }
        ],
        "services": [
            {
                text: 'Промивання порожнини носа за методом Проетц (кукушка)',
                price: 100
            },
            {
                text: 'Промивання лакун мигдаликів',
                price: 100
            },
            {
                text: 'Вимивання сірчаних корків',
                price: 100
            },
            {
                text: 'Продування слухових труб за Політцером',
                price: 100
            }
        ]
    },
    {
        "name": "ГІНЕКОЛОГІЯ",
        "treatments": "gynecology",
        "photo": photoGynecology,
        "consultations": [
            {
                text: 'Консультація гінеколога (встановлення діагнозу, спеціальний огляд, план обстеження, попередній план лікування)',
                price: 700
            }
        ],
        "services": [
            {
                text: 'Підбір індивідуальної схеми лікування',
                price: 900
            },
            {
                text: 'Забір матеріалу',
                price: 100
            },
            {
                text: 'Печінкові проби',
                price: 850
            },
            {
                text: 'Загальний аналіз сечі',
                price: 120
            },
            {
                text: 'Аналіз крові на цукор',
                price: 100
            },
            {
                text: 'Мікроскопія виділень (жінки)',
                price: 400
            },
            {
                text: 'Онкоцитологія (цитологія мазку)',
                price: 400
            },
            {
                text: 'Бакпосів на флору з антибіоткограмою',
                price: 500
            },
            {
                text: 'ПЦР діагностика хвороб, що передаються статевим шляхом\n' + '(real - time) 1 дослідження',
                price: 200
            },
            {
                text: 'Культуральне дослідження на мікоплазми (DUO)',
                price: 700
            },
            {
                text: 'ПЦР діагностика Герпес 1, 2',
                price: 300
            },
            {
                text: 'ПЦР діагностика Вірусу папіломи людини (1 тип)',
                price: 200
            },
            {
                text: 'Аналіз крові на групу та резус фактор',
                price: 200
            },
            {
                text: 'Визначення онкомаркера (СА: 19-9) в крові',
                price: 250
            },
            {
                text: 'Визначення онкомаркера (15-3) в крові',
                price: 250
            },
            {
                text: 'Визначення онкомаркера 125 + HE + H',
                price: 300
            },
            {
                text: 'Визначення антиспермальних антитіл (МАР-тест)',
                price: 300
            },
            {
                text: 'Лікування мікоплазмозу',
                price: 3900
            },
            {
                text: 'Лікування уреаплазмозу',
                price: 3900
            },
            {
                text: 'Лікування трихомоніазу',
                price: 3900
            },
            {
                text: 'Лікування хламідіозу',
                price: 3900
            },
            {
                text: 'Лікування захворювань вірусної етіології',
                price: 3900
            },
            {
                text: 'Лікування хронічного аднекситу',
                price: 3900
            },
            {
                text: 'Лікування хронічного бактеріального уретриту',
                price: 3900
            },
            {
                text: 'Лікування змішаної інфекції',
                price: 3900
            },
            {
                text: 'Базове обстеження (первинна консультація, кольпоскопічний огляд, мікроскопія мазку, онкоцитологія)',
                price: 2100
            },
            {
                text: 'Визначення рівня гормонів',
                price: 900
            },
            {
                text: 'Визначення імуноглобулінів G, M до хвороб, що передаються статевим шляхом',
                price: 200
            },
            {
                text: 'Ліпідограма',
                price: 250
            },
            {
                text: 'Коагулограма',
                price: 250
            },
            {
                text: 'Кольпоскопія розширена з візуалізацією',
                price: 500
            },
            {
                text: 'Видалення конділом генітальної ділянки',
                price: 450
            },
            {
                text: 'Кріодеструкція патологічних процесів шийки матки',
                price: 350
            },
            {
                text: 'Консервативне лікування ерозії шийки матки',
                price: 1500
            },
            {
                text: 'Лікування цервіциту',
                price: 2500
            },
            {
                text: 'Лікування бактеріального вагінозу (гарднерельозу)',
                price: 2500
            }
        ]
    },
    {
        "name": "ДЕРМАТОЛОГІЯ",
        "treatments": "dermatology",
        "photo": photoDermatology,
        "consultations": [
            {
                text: 'Консультація дерматолога (встановлення діагнозу, спеціальний огляд, план обстеження, попередній план лікування)',
                price: 700
            }
        ],

        "services": [
            {
                "text": "Видалення папілом та бородавок методом кріодеструкції (малих) до 1 мм",
                "price": 70
            },
            {
                "text": "Видалення папілом та бородавок методом кріодеструкції (середніх) до 3 мм",
                "price": 100
            },
            {
                "text": "Видалення папілом та бородавок методом кріодеструкції (великих) від 3 мм",
                "price": 150
            },
            {
                "text": "Видалення конділом генітальної ділянки",
                "price": 1500
            },
            {
                "text": "Кріомасаж обличчя (3 сеанси)",
                "price": 1500
            },
            {
                "text": "Електрокоагуляція папілом (малих) до 1 мм",
                "price": 70
            },
            {
                "text": "Електрокоагуляція папілом (середніх) до 3 мм",
                "price": 100
            },
            {
                "text": "Електрокоагуляція папілом (великих) від 3 мм",
                "price": 150
            },
            {
                "text": "Аналіз на антитіла до гельмінтів (лямблії, трихінели, опісторх, аскариди)",
                "price": 1200
            },
            {
                "text": "Аналіз на антитіла Ig G до Borrelia",
                "price": 150
            },
            {
                "text": "Аналіз на антитіла Ig М до Borrelia",
                "price": 250
            }
        ]
    },
    {
        "name": "АНДРОЛОГІЯ",
        "treatments": "andrology",
        "photo": photoAndrology,
        "consultations": [
            {
                text: 'Консультація андролога (встановлення діагнозу, спеціальний огляд, план обстеження, попередній план лікування)',
                price: 700
            }
        ],
        "services": [
            {
                text: 'Підбір індивідуальної схеми лікування запальних захворювань сечо-статевої системи',
                price: 900
            },
            {
                text: 'Забір матеріалу',
                price: 100
            },
            {
                text: 'Печінкові проби',
                price: 850
            },
            {
                text: 'Загальний аналіз сечі',
                price: 120
            },
            {
                text: 'Аналіз крові на цукор',
                price: 100
            },
            {
                text: 'Мікроскопія виділень з уретри (чоловіки)',
                price: 400
            },
            {
                text: 'Дослідження секрету передміхурової залози',
                price: 400
            },
            {
                text: 'Онкоцитологія (цитологія мазку)',
                price: 400
            },
            {
                text: 'Бакпосів на флору з антибіотикограмою',
                price: 500
            },
            {
                text: 'ПЦР діагностика хвороб, що передаються статевим шляхом (real - time) 1 дослідження',
                price: 200
            },
            {
                text: 'Культуральне дослідження на мікоплазми (DUO)',
                price: 700
            },
            {
                text: 'Аналіз крові на RW',
                price: 200
            },
            {
                text: 'Визначення антиспермальних антитіл (MAP-тест)',
                price: 350
            },
            {
                text: 'Визначення імуноглобулінів G, M до хвороб, що передаються статевим шляхом',
                price: 300
            },
            {
                text: 'Лікування мікоплазмозу',
                price: 3900
            },
            {
                text: 'Лікування уреаплазмозу',
                price: 3900
            },
            {
                text: 'Лікування трихомоніазу',
                price: 3900
            },
            {
                text: 'Лікування хламідіозу',
                price: 3900
            },
            {
                text: 'Лікування захворювань вірусної єтіології',
                price: 3900
            },
            {
                text: 'Лікування хронічного бактеріального уретриту',
                price: 3900
            },
            {
                text: 'Лікування змішаної інфекції',
                price: 3900
            },
            {
                text: 'Лікування хронічного простатиту',
                price: 4200
            },
            {
                text: 'Лікування захворювань вірусної етіології',
                price: 7000
            }
        ]
    },
    {
        "name": "ВЕНЕРОЛОГІЯ",
        "treatments": "venerology",
        "photo": photoVenerology,
        "consultations": [
            {
                text: 'Консультація венеролога (встановлення діагнозу, спеціальний огляд, план обстеження, попередній план лікування)',
                price: 700
            }
        ],
        "services": [
            {
                "text": "Підбір індивідуальної схеми лікування запальних захворювань сечо-статевої системи",
                "price": 900
            },
            {
                "text": "Забір матеріалу",
                "price": 100
            },
            {
                "text": "Печінкові проби",
                "price": 850
            },
            {
                "text": "Загальний аналіз сечі",
                "price": 120
            },
            {
                "text": "Аналіз крові на цукор",
                "price": 100
            },
            {
                "text": "Мікроскопія виділень з уретри (чоловіки)",
                "price": 400
            },
            {
                "text": "Дослідження секрету передміхурової залози",
                "price": 400
            },
            {
                "text": "Онкоцитологія (цитологія мазку)",
                "price": 400
            },
            {
                "text": "Бакпосів на флору з антибіоткограмою",
                "price": 500
            },
            {
                "text": "ПЦР діагностика хвороб, що передаються статевим шляхом (real - time) 1 дослідження",
                "price": 200
            },
            {
                "text": "Культуральне дослідження на мікоплазми (DUO)",
                "price": 700
            },
            {
                "text": "Аналіз крові на RW",
                "price": 200
            },
            {
                "text": "Аналіз крові на PSA (розгорнутий)",
                "price": 350
            },
            {
                "text": "Визначення антиспермальних антитіл (MAP-тест)",
                "price": 300
            },
            {
                "text": "Визначення імуноглобулінів G, M до хвороб, що передаються статевим шляхом",
                "price": 250
            },
            {
                "text": "Лікування мікоплазмозу",
                "price": 3900
            },
            {
                "text": "Лікування уреаплазмозу",
                "price": 3900
            },
            {
                "text": "Лікування трихомоніазу",
                "price": 3900
            },
            {
                "text": "Лікування хламідіозу",
                "price": 3900
            },
            {
                "text": "Лікування захворювань вірусної етіології",
                "price": 3900
            },
            {
                "text": "Лікування хронічного бактеріального уретриту",
                "price": 3900
            },
            {
                "text": "Лікування змішаної інфекції",
                "price": 3900
            },
            {
                "text": "Базове обстеження (первинна консультація, кольпоскопічний огляд, мікроскопія мазку, онкоцитологія)",
                "price": 4200
            },
            {
                "text": "Лікування хронічного простатиту",
                "price": 7000
            },
        ]
    },
    {
        "name": "МАМОЛОГІЯ",
        "treatments": "mamology",
        "photo": photoMamology,
        "consultations": [
            {
                text: 'Консультація мамолога (встановлення діагнозу, спеціальний огляд, план обстеження, попередній план лікування)',
                price: 700
            }
        ],
        "services": [
            {
                text: 'Визначення онкомаркеру (15-3) в крові',
                price: 250
            },
        ]
    },
    {
        "name": "УЗД",
        "treatments": "ultrasound",
        "photo": photoUltrasound,
        "consultations": [],
        "services": [
            {
                text: 'УЗД органів малого тазу (жінки) (сечовий міхур, залишкова сеча, яєчники, матка) абдомінальним датчиком',
                price: 600
            },
            {
                text: 'УЗД органів малого тазу (жінки) (сечовий міхур, залишкова сеча, яєчники, матка) вагінальним датчиком',
                price: 700
            },
            {
                text: 'УЗД молочних залоз',
                price: 700
            },
            {
                text: 'УЗД органів черевної порожнини (нирки, печінка, селезінка, підшлункова залоза, жовчний міхур)',
                price: 700
            },
            {
                text: 'УЗД щитовидної залози',
                price: 500
            }
        ]
    },
];
