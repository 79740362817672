import React from 'react';
import '@/styles/Doctors.css';
import DoctorItem from "../components/DoctorItem";
import PreFooter from "../components/Pre-footer";
import photo from '@/assets/slide1.jpg';

const Doctors = ({ doctorsData }) => {
    return (
        <>
            <div className='flex flex-col w-full h-[21rem] justify-center items-center shadow-xl'>
                <div className="image-container">
                    <img src={photo} className='brightness-50' alt="Image" />
                    <div className='overlay text-white flex justify-center items-center'>
                        <h1 className='uppercase'>Наші лікарі</h1>
                    </div>
                </div>
            </div>
            <div className='flex grow gap-2 justify-center items-start'>
                <div className='flex flex-col min-h-[70vh] pb-[25px] w-[80%] gap-[25px]'>
                    <div className="flex flex-wrap justify-center gap-[60px] p-[20px]">
                        {doctorsData.map((item, index) => (
                            <DoctorItem key={index} item={item} />
                        ))}
                    </div>
                </div>
            </div>
            <div className='flex flex-col w-full items-center'>
                {/*<div className='flex text-left font-semibold justify-center border-b-2 border-gray-200 m-[40px] w-[70%]'>
                    Звернувшись до нашого центру, Ви можете бути певні щодо високої якості послуг, адже досвідчені лікарі нададуть Вам кваліфіковану допомогу за доступними цінами. Фахівці центру допоможуть не тільки діагностувати захворювання на ранній стадії, а й на підставі проведених обстежень, причому в найкоротші терміни, складуть точний висновок та оптимальний план лікування.
                </div>*/}
                <PreFooter />
            </div>
        </>
    );
};

export default Doctors;
