import React from 'react';
import '@/styles/Price.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import ServiceItem from "../components/ServiceItem";
import PreFooter from "../components/Pre-footer";
import photo from '@/assets/slide2.jpg';

const Price = ({ customerServices }) => {
    return (
        <>
            <div className='flex flex-col w-full h-[21rem] justify-center items-center content-center shadow-xl'>
                <div className="image-container">
                    <img src={photo} className='brightness-50' alt="Services and Prices" />
                    <div className='overlay text-white justify-center items-center content-center'>
                        <h1 className='uppercase'>Послуги та ціни</h1>
                    </div>
                </div>
            </div>
            <div className='flex grow mt-[20px] gap-2 justify-center items-start'>
                <div className='flex flex-col min-h-[70vh]  pb-[25px] w-[70%] max-md:pl-[40px] max-md:w-full justify-start gap-[25px]'>
                    {customerServices.map((el, index) => (
                        <ServiceItem key={index} el={el} />
                    ))}
                </div>
            </div>
            <PreFooter />
        </>
    );
}

export default Price;
