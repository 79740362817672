import React from 'react';
import '@/styles/TreatmentsItem.css';

const TreatmentsItem = ({ href, img, name }) => {
    return (
        <a
            href={`/treatment/${href}`}
            className='flex flex-col gap-[22px] max-lg:gap-0 lg:transition lg:duration-1000 !no-underline cursor-pointer item border-3 border-tailprimary rounded-2xl w-[270px] h-[270px] max-lg:w-[180px] max-lg:h-[180px] justify-center items-center hover:bg-tailprimary'
        >
            <div className='flex w-[100px] max-lg:w-[80px] justify-center'>
                <img src={img} alt={name} className='icon-filter' />
            </div>
            <h2 className='text-[20px] max-lg:text-[16px] text-tailprimary pt-[10px]'>{name}</h2>
        </a>
    );
}

export default TreatmentsItem;
