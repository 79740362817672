import React, { useState, useEffect }  from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/App.css";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Price from "./pages/Price";
import Treatment from "./pages/Treatment";
import Contact from "./pages/Contact";
import Treatments from "./pages/Treatments";
import Doctors from "./pages/Doctors";
import Doctor from "./pages/Doctor";

import  {customerServices} from "@/scripts/services.js";
import  {doctorsData} from "@/scripts/doctors.js";
import ScrollToTopButton from "./components/ScrollToTopButton";

function App() {

    const [showMenu, setShowMenu] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 250) {
            setShowMenu(true);
        } else {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return (
        <div className="App">
            <Router>
                <Header/>
                <NavBar showMenu={showMenu}/>
                <Routes>
                    <Route path="/" element={<Home doctorsData={doctorsData}/>}/>
                    <Route path="/doctors" element={<Doctors doctorsData={doctorsData}/>}/>
                    <Route path="/doctors/:id"
                           element={<Doctor customerServices={customerServices} doctorsData={doctorsData}/>}/>
                    <Route path="/treatment" element={<Treatments/>}/>
                    <Route path="/treatment/:id"
                           element={<Treatment customerServices={customerServices} doctorsData={doctorsData}/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/price" element={<Price customerServices={customerServices}/>}/>
                </Routes>
            </Router>
            <Footer/>
            <ScrollToTopButton />
        </div>
    );
}

export default App;
