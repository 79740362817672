import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '@/styles/Contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const serviceID = 'ganimed.ua';
        const templateID = 'template_v3g3atp';
        const userID = 'f87wzmyCIA3S5nmR0';

        emailjs.send(serviceID, templateID, formData, userID)
            .then((response) => {
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
                alert('Повідомлення відправлено!');
            })
            .catch((error) => {
                alert('Повідомлення не відправлено.');
            });
    };

    return (
        <div className='flex flex-col w-full bg-gray-200'>
            <div className='relative w-full h-[580px] flex justify-center text-left'>
                <iframe
                    title="Google Maps"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2477.8342465103733!2d30.526970413068707!3d50.41745546206183!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4cf3ded6170e5%3A0x6c6d93e02985590a!2sHanimed.%20Medychnyy%20Tsentr!5e0!3m2!1sru!2sua!4v1719459626488!5m2!1sru!2sua"
                    allowFullScreen=""
                    loading="lazy"
                    className='absolute w-full h-[370px] grayscale'
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
                <div className='absolute top-[260px] w-[60%] max-md:w-full max-xl:w-[75%] max-lg:w-[85%] bg-tailprimary z-99'>
                    <div className='flex justify-between text-white font-semibold max-md:p-[20px] p-10'>
                        <div className="flex flex-col gap-4">
                            <h2 className="title">Київ, <span>Голосі́ївський район</span></h2>
                            <p>вулиця Ковпака, 3, Київ, 03150</p>
                            <a href="mailto:info@ganimed.ua"><span>Email:</span> info@ganimed.ua</a>
                        </div>
                        <div className="flex flex-col gap-4">
                            <div>
                                <h6>Дзвоніть нам:</h6>
                                <a href="tel:+380502038888">(050) 203 88 88</a>
                                <a href="tel:+380632373820">(063) 237 38 20</a>
                                <a href="tel:+380445286482">(044) 528 64 82</a>
                            </div>
                            <div>
                                <h6>Графік роботи:</h6>
                                <p>Пн - Пт: 09:00–15:30, Сб, Вск не працюємо.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className='flex flex-col w-[60%] max-md:w-full max-xl:w-[75%] max-lg:w-[85%] my-[20px] gap-[20px] self-center bg-white p-10'>
                <form onSubmit={handleSubmit}>
                    <div className='flex flex-col justify-center pt-[20px]'>
                        <h2 className='opacity-25'>КОНТАКТ</h2>
                        <div className='flex max-sm:flex-col gap-2 text-[32px] justify-center'>
                            <div className='text-tailprimary font-bold'>Ми Завжди Готові</div>
                            <div>Допомогти Вам</div>
                        </div>
                    </div>
                    <div className='flex flex-col w-full gap-[20px] h-[360px]'>
                        <div className='flex justify-between w-full p-[10px] gap-2'>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                className="form-control w-[30%]"
                                placeholder="Ім'я"/>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                className="form-control w-[30%]"
                                placeholder="Пошта"
                            />
                            <input
                                type="tel"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                                className="form-control w-[30%]"
                                placeholder="Номер"/>
                        </div>
                        <div className="flex p-[10px] mb-3">
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                className="form-control w-full resize-none"
                                placeholder="Напишіть своє повідомлення тут" rows="5"
                            ></textarea>
                        </div>
                        <div className='self-center'>
                            <button className="bg-tailprimary text-white rounded-[3px] p-2 hover:bg-black"
                                    type="submit">
                                Відправити
                            </button>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    );
}

export default Contact;
