import React, {useEffect, useState} from 'react';
import '@/styles/Home.css';
import { reviews } from "@/scripts/reviews.js";
import confirm from '@/assets/icons/confirm.svg';
import panoram from '@/assets/panoram.jpg';
import pillor from '@/assets/pillor.png';
import { CCarousel, CCarouselItem, CImage } from "@coreui/react";
import PreFooter from "../components/Pre-footer";
import Reviews from "../components/Reviews";

const groupElements = (array, chunkSize) => {
    return array.reduce((result, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!result[chunkIndex]) {
            result[chunkIndex] = [];
        }
        result[chunkIndex].push(item);
        return result;
    }, []);
};

const Home = ({ doctorsData }) => {

    const [isVisible, setIsVisible] = useState(false);


    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 910) {
                setIsVisible(true);
            } else {
            }
        };

        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const groupedDoctors = groupElements(doctorsData, 2);

    return (
        <div className='flex gap-2 justify-center'>
            <div className='flex flex-col w-full items-center justify-center gap-2'>
                <section className='flex flex-col w-full h-[560px]'>
                    <div className="image-container">
                        <img src={panoram} className='flex opacity-[0.50] w-full'
                             alt="Panoramic View of Medical Center"/>
                        <div className='flex overlay-home w-full justify-center text-black'>
                            <div
                                className='flex flex-col max-sm:w-full max-sm:h-full max-sm:pl-[10%] max-lg:w-[70%] mt-[20px] gap-1 w-[60%] text-left'>
                                <div>
                                    <h1 className={`max-sm:text-[40px] text-tailprimary`}>Медичний центр «ГАНІМЕД»</h1>
                                </div>
                                <div
                                    className='w-[60%] max-sm:hidden max-md:w-[85%] max-xl:w-[70%]  max-lg:w-[70%] italic font-semibold'>
                                    Вибираючи наш медичний центр, ви можете бути впевнені в найвищій якості
                                    обслуговування. Наші досвідчені медики забезпечать вам кваліфіковану допомогу за
                                    прийнятними цінами. Спеціалісти центру не тільки здатні виявити захворювання на
                                    ранньому етапі, але й швидко проведуть всі необхідні обстеження, щоб надати точний
                                    діагноз і створити оптимальний план лікування.
                                </div>
                                <ul className='flex flex-col mt-[10px] max-sm:gap-[25px] max-md:gap-0 max-lg:gap-[5px] lg:gap-[10px]'>
                                    {["Бездоганна якість", "Доступні ціни", "Кваліфікований персонал", "Турбота про пацієнтів", "Точний результат"].map((text, index) => (
                                        <li key={index} className='flex items-center gap-2'>
                                            <img src={confirm}
                                                 className='!w-[24px] !h-[24px] max-sm:!w-[40px] max-sm:!h-[40px]'
                                                 alt="Confirm Icon"/>
                                            <span className='font-semibold text-[18px] max-sm:py-[10px] max-sm:text-[24px]'>{text}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    className="flex h-[120px] w-[60%] max-sm:w-[90%] max-sm:h-[200px]   max-lg:h-[150px] max-lg:w-[75%] justify-between items-center">
                    {[{count: '1500+', text: "Пацієнти які отрімали нашу допомогу"},
                        {count: '7', text: "Професійних лікарів"},
                        {count: '15', text: "Років ми працюємо піклуючись про Ваше здоров'я"}].map((item, index) => (
                        <div key={index}
                             className={`flex-col w-[30%]   h-full p-2 justify-center content-center ${index === 1 ? 'border-r-2 border-l-2' : ''}`}>
                            <div className="flex justify-center items-center">
                                <h2 className='w-fit content-start border-b-[3px] px-4 border-tailprimary text-tailprimary font-bold'>
                                    {item.count}
                                </h2>
                            </div>
                            <p>{item.text}</p>
                        </div>
                    ))}
                </section>
                <PreFooter/>
                <section className='flex flex-col justify-center items-center w-full h-fit pt-[20px]'>
                    <h1 className='uppercase text-tailprimary max-lg:hidden'>Наші лікарі</h1>
                    <div className='flex gap-[20px] justify-center justify-between max-lg:hidden w-[60%] p-[20px]'>
                        <CCarousel interval={2500} className='w-[75vw] py-[40px]' controls indicators dark>
                            {groupedDoctors.map((group, index) => (
                                <CCarouselItem key={index}>
                                    <div className="flex justify-around gap-[5px]">
                                        {group.map((item, subIndex) => (
                                            <div key={subIndex} className='flex flex-col justify-between'>
                                                <div className='flex-col justify-between item-center'>
                                                    <CImage className="w-[450px] bg-white" src={item.photo}
                                                            alt={`${item.name}`}/>
                                                </div>
                                                <div className='flex-col h-full justify-between item-center'>
                                                    <h3 className='max-w-[420px] pt-[20px] justify-center'>{item.name}</h3>
                                                    <div>{item.doctor}</div>
                                                </div>
                                                <div className='flex justify-center items-end'>
                                                    <a href={`/doctors/${item.id}`}
                                                       className='z-[9999] bg-tailprimary transition ease-in-out duration-300 hover:bg-black hover:text-white text-white mt-[20px] p-2 w-fit self-center'>
                                                        Детальніше
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </CCarouselItem>
                            ))}
                        </CCarousel>
                    </div>
                </section>
                <section
                    className='relative flex flex-col bg-tailprimary justify-center items-center w-full text-white '>
                    <div
                        className={`flex absolute top-0 justify-center items-center py-[20px] transition duration-[4400ms] ${!isVisible && 'w-0 translate-x-[3200px]'} translate-x-[0px] `}>
                        <img src={pillor} className='transform -scale-y-100'/>
                        <img src={pillor} className='transform -scale-y-100'/>
                        <img src={pillor} className='transform -scale-y-100'/>
                    </div>
                    <div className={`flex flex-col py-[100px] justify-center items-center`}>
                        <h2>Про Клініку</h2>
                        <div className=' flex w-[70%]  text-[18px] items-center font-semibold'>
                            Клініка “Ганімед” створювалась, як сімейний бізнес. Завдяки сумлінному і чесному ставленню
                            до
                            справи, а також прекрасним результатам лікування, багатьом відоме прізвище Вергуленко, як
                            династія висококласних фахівців, що складають основу нашого центру. Наші лікарі мають
                            великий
                            досвід роботи, високі кваліфікаційні категорії, сертифікати, грамоти та нагороди, а також,
                            публікації в медичній літературі. Гнучкість наших програм медичного обслуговування дозволяє
                            запропонувати ту схему лікування, яка буде оптимальною саме для Вас. Ми надаємо перевагу
                            терапевтичному та профілактичному лікуванню і робимо все можливе, щоб пацієнт зміг уникнути
                            оперативного втручання. Ми не даємо порожніх обіцянок, і працюємо заради того, щоб Ви і
                            члени
                            Вашої сім’ї завжди були здоровими. Сподіваємося стати Вашим надійним партнером в збереженні
                            найціннішого в житті - здоров’я.
                        </div>
                    </div>
                    <div
                        className={`flex absolute bottom-0 justify-center items-center py-[20px] transition duration-[4400ms] ${!isVisible && 'w-0 translate-x-[-3200px]'} translate-x-[0px] `}>
                        <img src={pillor} className='transform scale-y-100'/>
                        <img src={pillor} className='transform scale-y-100'/>
                        <img src={pillor} className='transform scale-y-100'/>
                    </div>
                </section>
                <Reviews/>
            </div>
        </div>
    );
}

export default Home;
