import React, { useState, useEffect } from 'react';
import '@/styles/NavBar.css';
import { useLocation } from 'react-router-dom';

const NavBar = ({ showMenu }) => {
    const location = useLocation();
    const [menu, setMenu] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (location.pathname === '' && menu !== 0) {
            setMenu(0);
        } else if (location.pathname.startsWith('/doctors') && menu !== 1) {
            setMenu(1);
        } else if (location.pathname.startsWith('/treatment') && menu !== 2) {
            setMenu(2);
        } else if (location.pathname.startsWith('/contact') && menu !== 3) {
            setMenu(3);
        } else if (location.pathname.startsWith('/price') && menu !== 4) {
            setMenu(4);
        }
    }, [location, menu]);

    const menuItems = [
        { href: "/", label: "Головна" },
        { href: "/doctors", label: "Лікарі" },
        { href: "/treatment", label: "Лікування" },
        { href: "/contact", label: "Контакт" },
        { href: "/price", label: "Послуги та ціни" }
    ];

    const renderMenuItems = () => {
        return menuItems.map((item, index) => (
            <a
                key={index}
                href={item.href}
                className={`transition ease-in-out duration-300 m-4 ${menu === index && !isHovered ? 'active-menu' : ''}`}
            >
                {item.label}
            </a>
        ));
    };

    return (
        <>
            <div
                className='flex max-lg:hidden bg-tailprimary active-link justify-center items-center h-[60px]'
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className="flex justify-between items-center">
                    <div className='flex justify-center text-white gap-5'>
                        {renderMenuItems()}
                    </div>
                </div>
            </div>
            <div
                className={`fixed top-[-100px] shadow-md shadow-black !z-[99999999] active-link w-full bg-tailprimary text-white ${showMenu ? 'block' : 'hidden'}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className="container mx-auto p-[0px] flex max-lg:hidden justify-center items-center">
                    <div className='flex justify-center text-white gap-5'>
                        {renderMenuItems()}
                    </div>
                </div>
            </div>
        </>
    );
};

export default NavBar;
