import React, { useState } from 'react';
import { CCard, CCardBody, CCollapse } from "@coreui/react";

const ServiceItem = ({ el }) => {
    const [visible, setVisible] = useState(false);

    return (
        <div className='flex flex-col w-full border-b-1 border-black'>
            <div className='flex gap-2 max-md:pl-[40px]'>
                <button
                    className={`flex justify-center items-center  w-[50px] h-[50px] border-none transition duration-500 bg-tailprimary ${visible && 'bg-tailgray'} rounded-full`}
                    onClick={() => setVisible(!visible)}
                >
                    <svg
                        className={`transition fill-white duration-500 ${visible && 'rotate-[315deg]'}`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
                    </svg>
                </button>
                <div className={`flex items-center text-tailprimary transition duration-500 ${visible && 'text-tailgray'}`}>
                    <h2 className='flex items-center m-0'>{el.name}</h2>
                </div>
            </div>
            <CCollapse visible={visible} className='max-md:p-[10px]' >
                <CCard className="mt-3 border-none">
                    <CCardBody className='p-0'>
                        <div className='flex flex-col p-4 border-2 rounded-[3px] border-tailgray shadow-md'>
                            {el.consultations.length > 0 && <h3 className='py-2 text-left'>Консультації</h3>}
                            {el.consultations.map((c, index) => (
                                <div
                                    key={index}
                                    className={`flex border-b-2 py-[15px] border-tailgray ${index === 0 && 'border-t-2'} ${index % 2 === 0 && 'bg-gray-100'} pl-[20px] w-full text-left justify-between`}
                                >
                                    <div className='font-semibold'>{c.text}</div>
                                    <div className='font-bold pr-2'>{c.price}</div>
                                </div>
                            ))}
                            {el.services.length > 0 && <h3 className='py-2 text-left mt-[20px]'>Послуги</h3>}
                            {el.services.map((s, index) => (
                                <div
                                    key={index}
                                    className={`flex border-b-2 py-[15px] border-tailgray ${index === 0 && 'border-t-2'} ${index % 2 !== 0 && 'bg-gray-100'} pl-[20px] w-full text-left justify-between`}
                                >
                                    <div className='font-semibold'>{s.text}</div>
                                    <div className='font-bold pr-2'>{s.price}</div>
                                </div>
                            ))}
                        </div>
                    </CCardBody>
                </CCard>
            </CCollapse>
        </div>
    );
};

export default ServiceItem;
