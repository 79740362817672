import photoOleg from '@/assets/photos/Вергуленко Олег Іванович.png';
import photoNana from '@/assets/photos/ВЕРГУЛЕНКО (РУСАЙКІНА) НАНА ОЛЕГІВНА.png';
import photoAlla from '@/assets/photos/ВЕРГУЛЕНКО АЛЛА ОЛЕГІВНА.png';
import photoMykola from '@/assets/photos/Вергуленко Микола Іванович.png';
import photoSergiy from '@/assets/photos/ВЕРГУЛЕНКО СЕРГІЙ МИКОЛАЙОВИЧ.png';
import photoEvgeny from '@/assets/photos/ГЛУХЕНЬКИЙ ЄВГЕН ВІКТОРОВИЧ.png';
import photoSavchenkoSergiy from '@/assets/photos/САВЧЕНКО СЕРГІЙ ФІЛІПОВИЧ.png';
import photoSvetlana from '@/assets/photos/ДВОРСЬКА СВІТЛАНА ОЛЕСАНДРІВНА.png';

export const doctorsData = [
    {
        "id": 1,
        "name": "ВЕРГУЛЕНКО ОЛЕГ ІВАНОВИЧ",
        "photo": photoOleg,
        "doctor": 'ЛІКАР-АНДРОЛОГ, ВЕНЕРОЛОГ',
        "treatments": ["venerology","andrology"],
        "specialization": "лікар-венеролог, андролог вищої категорії",
        "directions": "венерологія, андрологія",
        "experience": "45 років",
        "treatment": {
            "name": "займається діагностикою та лікуванням",
            "text": [
                'Захворювання, що передаються статевим шляхом (хламідіоз\n' +
                'мікоплазмоз, уреаплазмоз, кандидоз, гарднерельоз та ін.);',
                'Гострі та хронічні запалення передміхурової залози;',
                'Зниження лібідо, передчасна еякуляція;',
                'Еректильна дисфункція, імпотенція, безпліддя'
            ]
        },
        "procedures": [
            'Забір матеріалу на дослідження на ЗПСШ;',
            'Забір секрету передміхурової залози для лабораторнго\n' +
            'дослідження;',
            'Пальцеве дослідження передміхурової залози та її масаж;',
            'Катетиризація та інстиляції в уретру;',
            'Фізіопроцедури (лазерно-ультрозвукова терапія,\n' +
            'пневмовібромасаж передміхурової залози, вакуумна стимуляція статевого члену, лікування інтердіном, тощо.)'
        ]
    },
    {
        "id": 2,
        "name": "ВЕРГУЛЕНКО МИКОЛА ІВАНОВИЧ",
        "photo": photoMykola,
        "doctor": 'ЛІКАР-ОТОЛАРИНГОЛОГ (ЛОР)',
        "treatments": ["otolaryngology"],
        "specialization": "лікар-отоларинголог вищої категорії",
        "directions": "дитяча та доросла",
        "experience": "32 роки",
        "treatment": {
            "name": "займається лікуванням",
            "text": [
                "патології ЛОР-органів:",
                "Аденоїдит, аденоїдні вегетації у дітей;",
                "Риніт, риносинусит (гострий та хронічний);",
                "Тонзиліт (гострий та хронічний);",
                "Ларингіт, трахеїт (гострий та хронічний);",
                "Середній отит (гострий та хронічний), зовнішній отит, євстахеїт"
            ]
        },
        "procedures": [
            "Промивання порожнини носа за методом Проетц (кукушка);",
            "Промивання лакун піднебінних мигдаликів;",
            "Видалення сірчаних корків"
        ]
    },
    {
        "id": 3,
        "name": "ВЕРГУЛЕНКО (РУСАЙКІНА) НАНА ОЛЕГІВНА",
        "photo": photoNana,
        "doctor": 'ЛІКАР-ДЕРМАТОЛОГ',
        "treatments": ["dermatology"],
        "specialization": "лікар-дерматолог вищої категорії",
        "directions": "дерматологія",
        "experience": "18 років",
        "treatment": {
            "name": "займається діагностикою та лікуванням захворювань шкіри",
            "text": [
                "Псоріаз, себорейний дерматит, екзема;",
                "Атопічний дерматит;",
                "Мікози;",
                "Акне;",
                "Пітиріаз рожевий (Жибера);",
                "Лишай висівковий"
            ]
        },
        "procedures": [
            "Видалення папілом та бородавок"
        ]
    },
    {
        "id": 4,
        "name": "ВЕРГУЛЕНКО АЛЛА ОЛЕГІВНА",
        "photo": photoAlla,
        "doctor": 'ЛІКАР-ГІНЕКОЛОГ, МАМОЛОГ, УЗД',
        "treatments": ["gynecology","ultrasound","mamology"],
        "specialization": "лікар-гінеколог, мамолог вищої категорії",
        "directions": "гінекологія, мамологія, УЗД діагностика",
        "experience": "19 років",
        "treatment": {
            "name": "займається діагностикою та лікуванням",
            "text": [
                "Ерозії шийки матки;",
                "Цервіцит;",
                "Бактеріальний вагіноз;",
                "Мікоплазмоз, уреаплазмоз, трихомоніаз, хламідіоз;",
                "Захворювання вірусної етіології;",
                "Хронічний аднексит;",
                "Дисплазія шийки матки;",
                "Ендокринні захворювання"
            ]
        },
        "procedures": [
            "Біопсія шийки матки;",
            "Радіохвильова коагуляція шийки матки;",
            "Кріодетрукція шийки матки;",
            "Видалення кондилом аногенітальної зони та вульви;",
            "УЗД органів малого тазу, молочних залоз, черевної порожнини, щитоподібної залози"
        ]
    },
    {
        "id": 5,
        "name": "ВЕРГУЛЕНКО СЕРГІЙ МИКОЛАЙОВИЧ",
        "photo": photoSergiy,
        "doctor": 'ЛІКАР-ОТОЛАРИНГОЛОГ (ЛОР)',
        "treatments": ["otolaryngology",],
        "specialization": "лікар-отоларинголог другої категорії",
        "directions": "дитяча та доросла отоларингологія",
        "experience": "9 років",
        "treatment": {
            "name": "займається лікуванням гострих та хронічних патологій ЛОР-органів",
            "text": [
                "Аденоїдит, аденоїдні вегетації у дітей;",
                "Риніт, риносинусит (гострий та хронічний);",
                "Тонзиліт (гострий та хронічний);",
                "Ларингіт, трахеїт (гострий та хронічний);",
                "Середній отит (гострий та хронічний), зовнішній отит, євстахеїт"
            ]
        },
        "procedures": [
            "Промивання порожнини носа за методом Проетц (кукушка);",
            "Промивання лакун піднебінних мигдаликів;",
            "Видалення сірчаних корків"
        ]
    },
    {
        "id": 6,
        "name": "ГЛУХЕНЬКИЙ ЄВГЕН ВІКТОРОВИЧ",
        "photo": photoEvgeny,
        "doctor": 'ЛІКАР-ДЕРМАТОВЕНЕРОЛОГ, УЗД',
        "treatments": ["dermatology","ultrasound"],
        "specialization": "лікар-дерматовенеролог (магістр), УЗД",
        "directions": "дерматовенерологія, УЗД",
        "experience": "34 роки",
        "treatment": {
            "name": "діагностика та лікування захворювань шкіри",
            "text": [
                "Псоріаз, себорейний дерматит, екзема;",
                "Акне, розацеа;",
                "Дерматити різного походження;",
                "Захворювання нігтів та волосся;"
            ]
        },
        "procedures": [
            "Дерматоскопія;",
            "Діатермокоагуляція;",
            "Видалення доброякісних новоутворень;",
            "Кріодеструкція;",
            "УЗД (черевної порожнини, органів малого тазу, щитоподібної залози)"
        ]
    },
    {
        "id": 8,
        "name": "ДВОРСЬКА СВІТЛАНА ОЛЕКСАНДРІВНА",
        "photo": photoSvetlana,
        "doctor": 'ЛІКАР-ДЕРМАТОВЕНЕРОГЛОГ',
        "treatments": ["dermatology"],
        "specialization": "лікар-дерматовенеролог вищої категорії",
        "directions": "дерматовенерологія",
        "experience": "20 років",
        "treatment": {
            "name": "діагностика та лікування захворювань шкіри",
            "text": [
                "Псоріаз, себорейний дерматит, екзема;",
                "Герпес простий, герпес зостер, бородавки, кондиломи;",
                "Оніхомікоз, оніходистрофія;",
                "Атопічний дерматит;",
                "Захворювання, що передаються переважно статевим шляхом"
            ]
        },
        "procedures": [
            "Дерматоскопія;",
            "Кріотерапія"
        ]
    }
];
