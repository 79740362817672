import React from 'react';

function Footer(props) {
    return (
        <div className='w-full bg-tailprimary text-white'>
            <div className="flex">
                <div className="flex w-full justify-center">
                        <div className="flex items-center">
                            <div className="t">
                                <div className='flex items-center gap-1'>
                                    © 2023 <span className="text-uppercase">ГАНIМЕД</span>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;